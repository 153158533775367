export function getReturnPointsList(params) {
  return this.$api.get('return-point/list', params)
    .then(({ data }) => {
      return data
    })
    .catch(error => Promise.reject(error))
}

export default {
  methods: {
    getReturnPoints(params) {
      return this.$api.get('return-points', params)
        .then(({ data }) => {
          return data
        })
        .catch(error => Promise.reject(error))
    },

    getReturnPoint(id) {
      return this.$api.get('return-point/' + id)
        .then(({ data }) => {
          return data
        })
        .catch(error => Promise.reject(error))
    },

    createReturnPoint(params) {
      return this.$api.post('return-point/0', params)
        .then(({ data }) => {
          return data
        })
        .catch(error => Promise.reject(error))
    },

    updateReturnPoint(id, params) {
      return this.$api.post('return-point/' + id, params)
        .then(({ data }) => {
          return data
        })
        .catch(error => Promise.reject(error))
    }
  }
}
