<template>
  <rcc-text-input
    v-model="dateFormatted"
    mask="##.##.####"
    :label="label"
    :width="width"
    :is-disabled="isDisabled"
    :rules="allRules"
    v-bind="$attrs"
    ref="inputRef"
  >
    <template v-slot:append>
      <v-menu
        v-model="isShowMenu"
        :close-on-content-click="false"
        transition="slide-y-transition"
        :attach="attach"
        :content-class="menuClass"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            width="24"
            height="24"
            icon
            v-bind="attrs"
            v-on="on"
            :disabled="isDisabled"
          >
            <v-icon>mdi-calendar</v-icon>
          </v-btn>
        </template>

        <v-date-picker v-model="selectedDate" locale="ru"/>
      </v-menu>
    </template>
  </rcc-text-input>
</template>

<script>
import RccTextInput from './rcc-text-input'

export default {
  name: 'RccDateInput',

  inheritAttrs: false,

  components: { RccTextInput },

  props: {
    label: {
      type: String,
      default: ''
    },

    width: {
      type: String,
      default: null
    },

    isDisabled: {
      type: Boolean,
      default: false
    },

    rules: {
      type: Array,
      default: () => []
    },

    value: {
      type: String,
      default: null
    },

    pickerAttach: {
      type: Boolean,
      default: false
    },

    menuClass: {
      type: String,
      default: undefined
    },

    closeOnSelectDate: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isMounted: false,
      isShowMenu: false
    }
  },

  computed: {
    allRules() {
      return [...this.rules, this.dateValidation]
    },

    attach() {
      return this.pickerAttach && this.isMounted ? this.$refs?.inputRef?.$el : false
    },

    selectedDate: {
      get() {
        return (this.isCorrectDate(this.value) && this.value) || null
      },

      set(value) {
        this.isShowMenu = false
        this.$emit('input', value)
      }
    },

    dateFormatted: {
      get() {
        return (this.value && this.value.split('-').reverse().join('.')) || null
      },

      set(value) {
        this.$emit('input', (value && value.split('.').reverse().join('-')) || null)
      }
    }
  },

  mounted() {
    this.isMounted = true
  },

  methods: {
    dateValidation() {
      if (!this.value) {
        return true
      }

      return (
        this.isCorrectDate(this.value) || 'Неверная дата. Введите дату в формате ДД.ММ.ГГГГ'
      )
    },

    isCorrectDate(date) {
      return /^\d{4}-\d{2}-\d{2}$/.test(date) && this.$moment(date)._isValid
    }
  }
}
</script>

<style lang="scss" scoped>
.v-menu__content {
  max-width: unset;
}
</style>
